import { Power3, TweenLite } from "gsap"
import React, { useEffect, useMemo, useRef, useContext } from "react"
import { Color, HeroGridTheme } from "../../../data/style/variables"
import { CtaArrow, Rotations, Sizes } from "../CtaArrow/CtaArrow"
import { Grid } from "../Grid/Grid"
import { RevealBlockAnimation } from "../RevealBlockAnimation/RevealBlockAnimation"
import { Section } from "../Section/Section"
import { InfoSpan, TitleH1 } from "../Typography/Typography"
import {
  BottomBlockContainerElement,
  ContainerElement,
  CtaArrowContainerElement,
  HeroInnerContainerElement,
  TechnicalDetailsElement,
  TitleContainerElement
} from "./styled"
import CtaArrowAnimation from "../CtaArrowAnimation"
import { PageContext } from "../../context/PageManager/PageManager"
import { TypographyAnimation } from "../TypographyAnimation/TypographyAnimation"

type ProductHeroProps = {
  title: string
  image?: string
  background?: string
  technicalDetails: string
  pageWidget?: JSX.Element
  infoTexts?: string[]
}

export const ProductHero: React.FC<ProductHeroProps> = ({
  title,
  image,
  background,
  technicalDetails,
  pageWidget,
  infoTexts,
}) => {
  const pageTitleRef = useRef<HTMLDivElement>(null)
  const pageTechnicalDetail = useRef<HTMLDivElement>(null)
  const pageContext = useContext(PageContext)
  const memoizedGridElementProps = useMemo(
    () => ({
      noPadding: true,
      backgroundImage: image
        ? `linear-gradient(rgba(0, 0, 0, 0), #0000009e), url(${image}) no-repeat center center/cover`
        : undefined,
      background: background ? background : undefined,
      baseHeight: "100vh",
      ...HeroGridTheme,
      noPaddingBottom: true,
    }),
    [image]
  )

  useEffect(() => {
    if (pageTitleRef.current && pageContext.pageLoaded) {
      TweenLite.fromTo(
        pageTitleRef.current,
        1.2,
        { opacity: 0, x: -75 },
        { opacity: 1, x: 0, ease: Power3.easeOut }
      )
    }
    if (pageTechnicalDetail.current && pageContext.pageLoaded) {
      TweenLite.fromTo(
        pageTechnicalDetail.current,
        1.2,
        { opacity: 0, y: -25 },
        { opacity: 1, y: 0, ease: Power3.easeOut }
      )
    }
  }, [pageContext.pageLoaded])

  return (
    <ContainerElement>
      {/* <TextureOverlayElement src={crossSvg} /> */}
      <Grid gridElementProps={memoizedGridElementProps}>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          <HeroInnerContainerElement>
            <TechnicalDetailsElement ref={pageTechnicalDetail}>
              {technicalDetails}
            </TechnicalDetailsElement>
            <TitleContainerElement>
              <div ref={pageTitleRef}>
                <RevealBlockAnimation background={Color.PrimaryLight}>
                  <TitleH1 color={Color.PrimaryLight}>{title}</TitleH1>
                </RevealBlockAnimation>
              </div>
            </TitleContainerElement>
          </HeroInnerContainerElement>
          {infoTexts && (
            <TypographyAnimation>
              <BottomBlockContainerElement background={Color.Test1}>
                <InfoSpan color={Color.Black}>{infoTexts[0]}</InfoSpan>
              </BottomBlockContainerElement>
            </TypographyAnimation>
          )}
        </Section>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          {infoTexts && (
            <TypographyAnimation index={1}>
              <BottomBlockContainerElement background={Color.Test2}>
                <InfoSpan color={Color.Black}>{infoTexts[1]}</InfoSpan>
              </BottomBlockContainerElement>
            </TypographyAnimation>
          )}
        </Section>
        <Section
          sectionElementProps={{
            color: "white",
            noPadding: true,
            justifyEnd: true,
          }}
        >
          {/* {pageWidget && pageWidget} */}
          <CtaArrowContainerElement>
            <CtaArrowAnimation>
              <CtaArrow
                color={Color.White}
                rotation={Rotations.down}
                size={Sizes.large}
              />
            </CtaArrowAnimation>
          </CtaArrowContainerElement>
          {infoTexts && (
            <TypographyAnimation index={2}>
              <BottomBlockContainerElement background={Color.Test3}>
                <InfoSpan color={Color.Black}>{infoTexts[2]}</InfoSpan>
              </BottomBlockContainerElement>
            </TypographyAnimation>
          )}
        </Section>
      </Grid>
    </ContainerElement>
  )
}
