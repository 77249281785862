import React from "react"
import { TimeManagement as TimeManagementComponent } from "../components/pages/TimeManagement/TimeManagement"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from '../data/style/variables';

const TimeManagement = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <TimeManagementComponent />
  </Layout>
)

export default TimeManagement
