import React from "react"
import { ProductHero } from "../../../../../general/ProductHero/ProductHero"
import { PageWidget } from "../../../../../general/PageWidget/PageWidget"
import { useStaticQuery, graphql } from "gatsby"
import { Color } from "../../../../../../data/style/variables"
import {
  ContainerElement,
  LineContainerElement,
  LineImgElement,
  LineHolderElement
} from "./styled"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "shutterstock_782843011.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <ProductHero
        title="ERP systemen voor de industrie."
        image={imageData.heroImage.childImageSharp.fluid.src}
        technicalDetails="Desktop - Cloud - Mobiel - Business"
      />
    </ContainerElement>
  )
}
