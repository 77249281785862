import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { TitleH2, Paragraph } from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const About: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
      >
        <Section>
          <TypographyAnimation>
            <TitleH2 color={Color.Black}>
              Op zoek naar een <Bold>ERP</Bold> systeem dat bij u bedrijf past?
              We maken ERP systemen voor de industrie, onze systemen zijn
              geschikt voor de kleinere ondernemer en de grote fabrieken.
            </TitleH2>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
