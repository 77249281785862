import React from "react"
import "typeface-roboto-mono"
import { Footer } from "../Home/sections/Footer/Footer"
import { About } from './sections/About/About'
import { Hero } from "./sections/Hero/Hero/Hero"
import { ContainerElement } from "./styled"
import { Info } from "./sections/Info/Info"

export const TimeManagement: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <About/>
      <Info/>
      <Footer />
    </ContainerElement>
  )
}
