import styled from "styled-components/macro"
import { Color } from "../../../data/style/variables"

type ContainerElementProps = {
  src: string
}

export const TextureOverlayElement = styled.div<ContainerElementProps>`
  background-image: url('${props => props.src}');
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  opacity: .15;
  background-size: 60px;
`

export const ContainerElement = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

export const TitleContainerElement = styled.div`
  @media (max-width: 768px) {
    margin-top: 125px;
    margin-bottom: 25px;
  }
`

export const TechnicalDetailsElement = styled.div`
  position: absolute;
  opacity: 0;
  transform: rotate(90deg);
  left: -185px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: "Roboto Mono", sans-serif;
`

export const HeroInnerContainerElement = styled.div`
  height: 100%;
  padding-left: 25px;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    width: 200%;
  }
`

export const CtaArrowContainerElement = styled.div`
  position: absolute;
  bottom: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
`

type BottomBlockContainerElementProps = {
  background: Color
}

export const BottomBlockContainerElement = styled.div<
  BottomBlockContainerElementProps
>`
  background: ${props => props.background};
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-child(1) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    display: none;
  }
`
