import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import {
  TitleH2,
  Paragraph,
  TitleH3,
} from "../../../../general/Typography/Typography"
import { ContainerElement } from "./styled"

export const Info: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "500px",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
      >
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Plugins voor uw CAD programma.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Plugins voor bijvoorbeeld het <Bold>automatisch</Bold> generen van
              tekeningen en het beheren van een bibliotheek vanuit een ERP
              systeem.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Koppelingen aan uw bestaande software.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Heeft u een ERP systeem met veel artikelen en recepturen? Dan kan
              Disc die biblitheek koppelen aan uw CAD programma. Hiermee kunt u
              het tekenproces versnellen door het beheer aan het ERP over te
              laten.
            </Paragraph>
          </TypographyAnimation>
        </Section>
        <Section>
          <TypographyAnimation>
            <TitleH3 color={Color.Black}>
              Configurators.
            </TitleH3>
            <Paragraph color={Color.Black}>
              Uw maatwerk-producten in uw CAD programma configureren en tekenen.
            </Paragraph>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
